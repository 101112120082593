import React from 'react';
import { css } from '@emotion/core';
import { Layout, Main } from 'src/components/layout';
import { RootWrapper } from 'src/components/RootWrapper';
import { IndexHeader, IndexFooter } from 'src/components/pages/index';
import { NuevosProyectosContent } from 'src/components/pages/nuevos-proyectos';
import { TextContextProvider } from 'src/components/TextContext';
import esTextCommon from '../../text/es/common.yaml';
import esTextNuevosProyectos from '../../text/es/nuevos-proyectos.yaml';
import enTextCommon from '../../text/en/common.yaml';
import enTextNuevosProyectos from '../../text/en/nuevos-proyectos.yaml';

const esText = { ...esTextCommon, ...esTextNuevosProyectos };
const enText = { ...enTextCommon, ...enTextNuevosProyectos };
const text = {
  es: esText,
  en: enText,
};

const QuienesSomosPage = ({ location }) => {
  return (
    <RootWrapper>
      <TextContextProvider text={text}>
        <Layout>
          <IndexHeader backgroundVisibility={1} location={location} />
          <Main
            css={css`
              margin-top: 6rem;
            `}
          >
            <NuevosProyectosContent />
          </Main>
          <IndexFooter />
        </Layout>
      </TextContextProvider>
    </RootWrapper>
  );
};

export default QuienesSomosPage;
