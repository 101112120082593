import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import { SectionHeader, SectionDivider } from 'src/components/pages/index';
import { Grid, Paper, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import { useText, useLanguage } from 'src/components/TextContext';
import { MailOutlined } from '@material-ui/icons';

import imgAlphaCentral from '../../../../images/new-proyects/alfa-central.jpg';
import enImgGold from '../../../../images/new-proyects/gold-en.jpg';
import esImgGold from '../../../../images/new-proyects/gold-es.jpg';
import enImgTaurus from '../../../../images/new-proyects/taurus-en.jpg';
import esImgTaurus from '../../../../images/new-proyects/taurus-es.jpg';

const ProjectContainer = ({ src }) => (
  <Grid
    item
    xs={12}
    md={4}
    css={css`
      overflow: hidden;
      max-width: 100%;
    `}
  >
    <motion.div whileHover={{ scale: 1.1 }}>
      <Paper
        css={css`
          display: flex;
        `}
      >
        <img
          src={src}
          css={css`
            margin: auto;
            max-width: 100%;
            max-height: 100%;
          `}
        />
      </Paper>
    </motion.div>
  </Grid>
);

const ContactUsLink = () => {
  const t = useText();

  return (
    <div
      css={css`
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
      `}
    >
      <Typography
        variant="h4"
        css={css`
          margin: auto;
          display: flex;
          align-items: center;
        `}
      >
        {t.nuevosProyectos.forMoreInformation}
        <Link
          to="/contacto"
          css={css`
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-left: 0.5em;
          `}
        >
          {t.nuevosProyectos.contactUs}
          <MailOutlined
            css={css`
              font-size: 2rem;
              margin-left: 0.5em;
            `}
          />
        </Link>
      </Typography>
    </div>
  );
};

export const NuevosProyectosContent = () => {
  const t = useText();
  const { currentLanguage } = useLanguage();

  const images =
    currentLanguage == 'es'
      ? [imgAlphaCentral, esImgGold, esImgTaurus]
      : [imgAlphaCentral, enImgGold, enImgTaurus];

  return (
    <div
      css={css`
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        grid-gap: 1rem;
      `}
    >
      <SectionHeader>{t.nuevosProyectos.title}</SectionHeader>
      <SectionDivider />
      <ContactUsLink />

      <Grid
        container
        css={css`
          padding: 1rem;
          max-width: 100%;
        `}
        spacing={3}
        justify="space-around"
      >
        {images.map((x) => (
          <ProjectContainer src={x} />
        ))}
      </Grid>
    </div>
  );
};

export default NuevosProyectosContent;
